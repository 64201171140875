import { useState } from "preact/hooks"
import { Button } from "./ui/button"
import { Interlude, COMPANY_EMAIL } from "../landing/util"

export function ContactForm() {
  const [formStatus, setFormStatus] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle')
  
  const handleSubmit = async (e: Event) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    
    // Validate that either email or phone is provided
    const email = form.email.value
    const phone = form.phone.value
    if (!email && !phone) {
      alert('Please provide either an email address or phone number')
      return
    }

    setFormStatus('submitting')

    try {
      const formData = new FormData(form)
      await fetch(form.action, {
        method: 'POST',
        body: formData,
        headers: {
          'Accept': 'application/json'
        }
      })
      
      setFormStatus('success')
      form.reset()
      
      // Reset success status after 3 seconds
      setTimeout(() => {
        setFormStatus('idle')
      }, 3000)
      
    } catch (error) {
      console.error('Form submission error:', error)
      setFormStatus('error')
      
      // Reset error status after 3 seconds
      setTimeout(() => {
        setFormStatus('idle')
      }, 3000)
    }
  }

  return (
    <section id="contact" className="bg-sapphire-100 py-16">
      <div className="max-w-3xl mx-auto px-4">
        <Interlude
          badgeCopy="Contact"
          title="Get in Touch"
          description="Need help with a plumbing or heating issue? Fill out this form and we'll get back to you asap (usually we respond within an hour but sometimes in just a few minutes)."
          className="mb-8"
        />

        <form 
          action={`https://formsubmit.co/${COMPANY_EMAIL}`}
          method="POST"
          className="space-y-6"
          onSubmit={handleSubmit}
        >
          {/* These hidden inputs customize FormSubmit behavior */}
          <input type="hidden" name="_subject" value="New Contact Form Submission" />
          {/* <input type="hidden" name="_template" value="table" />
          <input type="hidden" name="_next" value={window.location.origin + "?submitted=true"} /> */}

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label htmlFor="name" className="block font-medium text-sapphire-900 mb-1">
                Name *
              </label>
              <input
                type="text"
                id="name"
                name="name"
                required
                className="w-full px-4 py-2 border border-sapphire-200 rounded-lg focus:ring-2 focus:ring-sapphire-500 focus:border-transparent"
              />
            </div>

            <div>
              <label htmlFor="phone" className="block font-medium text-sapphire-900 mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                className="w-full px-4 py-2 border border-sapphire-200 rounded-lg focus:ring-2 focus:ring-sapphire-500 focus:border-transparent"
              />
            </div>
          </div>

          <div>
            <label htmlFor="email" className="block font-medium text-sapphire-900 mb-1">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="w-full px-4 py-2 border border-sapphire-200 rounded-lg focus:ring-2 focus:ring-sapphire-500 focus:border-transparent"
            />
            <p className="mt-1 text-sm text-sapphire-600">
              * Either email or phone number is required
            </p>
          </div>

          <div>
            <label htmlFor="message" className="block font-medium text-sapphire-900 mb-1">
              How can we help? *
            </label>
            <textarea
              id="message"
              name="message"
              required
              rows={4}
              className="w-full px-4 py-2 border border-sapphire-200 rounded-lg focus:ring-2 focus:ring-sapphire-500 focus:border-transparent"
            />
          </div>

          <div className="flex justify-center">
            <div className="rounded-md shadow">
              <Button 
                type="submit"
                disabled={formStatus === 'submitting'}
                variant="primary"
                size="lg"
              >
                {formStatus === 'submitting' ? 'Sending...' : 'Send Message'}
              </Button>
            </div>
          </div>

          {formStatus === 'success' && (
          <div className="mb-6 p-4 bg-green-100 text-green-700 rounded-lg text-center">
            Thank you! We'll be in touch soon.
          </div>
        )}

        {formStatus === 'error' && (
          <div className="mb-6 p-4 bg-red-100 text-red-700 rounded-lg text-center">
            Something went wrong. Please try again or call us directly.
          </div>
        )}
        </form>
      </div>
    </section>
  )
} 